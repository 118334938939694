<template>
  <div>
    <b-table
      striped
      hover
      :items="journeyDataList"
      :fields="fields"
      :busy="isLoading"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>

          <b-dropdown-item @click="editItem(item)">
            <feather-icon icon="EditIcon" class="mr-75" />
            Update
          </b-dropdown-item>

          <b-dropdown-item @click="deleteItem(item.uuid)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-journey"
      centered
      :title="editIdModal != null ? 'Edit Journey' : 'Add Journey'"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <ValidationObserver>
        <div class="form">
          <div class="row mb-4">
            <b-col cols="12" lg="6">
              <div class="form-group">
                <label>Title:</label>
                <validation-provider
                  name="title"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="text"
                    class="form-control"
                    :class="classes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                    v-model="formPayloadJourney.title"
                  />
                  <small
                    v-for="(validation, index) in validationsForm.title"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="form-group">
                <label for="type">Tipe:</label>
                <validation-provider name="type" rules="required">
                  <div class="demo-inline-spacing" style="margin-top: 8px">
                    <div class="custom-control custom-radio mt-0">
                      <input
                        name="typeOptions"
                        class="custom-control-input"
                        type="radio"
                        id="radio1"
                        value="active"
                        v-model="formPayloadJourney.status"
                      />
                      <label class="custom-control-label" for="radio1">
                        Active
                      </label>
                    </div>
                    <div class="custom-control custom-radio mt-0">
                      <input
                        name="typeOptions"
                        class="custom-control-input"
                        type="radio"
                        id="radio2"
                        value="inactive"
                        v-model="formPayloadJourney.status"
                      />
                      <label class="custom-control-label" for="radio2">
                        Inactive
                      </label>
                    </div>
                  </div>
                  <small
                    v-for="(validation, index) in validations.status"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
            </b-col>
          </div>
          <h3>Contents</h3>
          <div
            v-for="(content, index) in formPayloadJourney.content"
            :key="index"
            class="mt-2"
          >
            <div class="d-flex justify-content-between align-items-center mb-1">
              <h4 class="mb-0">Content {{ index + 1 }}</h4>
              <b-button
                variant="danger"
                class="btn waves-effect waves-float waves-light btn-danger"
                @click="removeContent(index)"
                v-show="index > 0"
              >
                Delete Contents
              </b-button>
            </div>
            <b-row>
              <b-col cols="12" lg="6">
                <div class="form-group">
                  <label>Image:</label>
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="changePhoto($event, 'image', index)"
                  />
                  <div
                    v-if="formPayloadJourney.content[index].image"
                    class="mt-1"
                  >
                    <img
                      :src="formPayloadJourney.content[index].image"
                      class="w-100"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="6">
                <div class="form-group">
                  <label>Position:</label>
                  <!-- <validation-provider
                    name="title"
                    rules="required"
                    v-slot="{ errors, classes }"
                  > -->
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Title"
                    v-model="content.position"
                  />
                  <!-- <small
                      v-for="(validation, index) in validationsForm.title"
                      :key="`errorName${index}`"
                      class="text-danger"
                      >{{ validation }}</small
                    >
                  </validation-provider> -->
                </div>
              </b-col>
            </b-row>
          </div>
          <b-button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary mt-1"
            @click="addContents"
          >
            Add Content
          </b-button>
        </div>
        <div v-if="isLoading">
          <br />
          <b-spinner class="mb-2" variant="primary" /><br />
        </div>
        <b-button
          v-if="editIdModal == null"
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Save Journey
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit Journey
        </b-button>
      </ValidationObserver>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BCol,
  BDropdown,
  BPagination,
  BDropdownItem,
  BFormFile,
  BRow,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BCol,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BFormFile,
    BRow,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    journeyData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    addContents: {
      type: Function,
    },
    removeContent: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    journeyData(value) {
      this.journeyDataList = value;
    },
    formPayload(value) {
      this.formPayloadJourney = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      journeyDataList: [],
      editIdModal: null,
      formPayloadJourney: {
        title: "",
        stattus: "",
        contents: [],
      },
      validationsForm: "",
      fields: [
        { key: "no" },
        { key: "title" },
        { key: "status" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.journeyDataList.length;
    },
  },
};
</script>

<style></style>
