<template>
  <b-card title="Journey List">
    <b-row class="mb-3 justify-content-between">
      <b-col cols="12" lg="4" class="d-flex align-items-center">
        <b-button
          @click="cleanUpForm()"
          v-b-modal.form-journey
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Create Journey
        </b-button>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
        <div class="form-group mb-0">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :journeyData="journeyData"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :formPayload="formPayload"
      :edit-item="editItem"
      :update-item="updateItem"
      :create-item="createItem"
      :current-page="currentPage"
      :editId="editId"
      :addContents="addContents"
      :remove-content="removeContent"
      :validations="validations"
    />
  </b-card>
</template>

<script>
import Table from "@/components/journey/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      journeyData: {},
      formPayload: {
        title: "",
        status: "",
        contents: [],
      },
      editId: null,
      filter: {},
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        title: "",
        status: "",
        content: [],
      };
    },
    editItem(item) {
      this.cleanUpForm();
      (this.editId = item.uuid),
        (this.formPayload = {
          title: item.title,
          status: item.status,
          content: [],
        });
      for (let index = 0; index < item.content.length; index++) {
        const contentItem = item.content[index];
        const contentData = {
          position: contentItem.position,
          image: contentItem.image,
        };
        this.formPayload.content.push(contentData); 
      }
      this.$bvModal.show("form-journey");
    },
    addContents() {
      if (this.formPayload.content.length + 1 <= 2) {
        this.formPayload.content.push({
          position: "",
          image: null,
        });
      }
    },
    removeContent(index) {
      this.formPayload.content.splice(index, 1);
    },
    createItem() {
      this.isLoading = true;
      this.$http
        .post("/api/v1/admin/journeys/create", this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-journey");
          this.getData(this.currentPage);
          successNotification(this, "Success", "Journey successfully created");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    updateItem() {
      this.isLoading = true;
      const payload = new FormData();
      payload.append("title", this.formPayload.title);
      payload.append("status", this.formPayload.status);
      if (this.formPayload.content !== null) {
        this.formPayload.content.forEach((content, index) => {
          Object.keys(content).forEach((key) => {
            if (key != "image" && key != "position") {
              payload.append(
                "contents" + "[" + index + "][" + key + "]",
                content[key]
              );
            }
          });
        });
      }
      this.$http
        .post(`/api/v1/admin/journeys/update/${this.editId}`, this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-journey");
          this.getData();
          successNotification(this, "Success", "Journey successfully updated!");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },
    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/api/v1/admin/journeys/list", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.journeyData = response.data.data.items;
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Journey?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/post-categories/remove/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Journey successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
